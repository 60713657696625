<template>
    <div class="card card-custom">
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">{{ $t("settings.heading.apperance") }}</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">{{ $t("settings.subheading.apperance") }}</span>
            </div>
            <div class="card-toolbar">
                <b-button class="btn btn-success mr-2" @click.prevent="saveLogo" ref="save_submit">{{ $t("button.save") }}</b-button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12 col-xl-6">   
                        <div class="dropzone dropzone-default">
                            <div class="dropzone-msg dz-message needsclick">
                                <div @click="$refs.LogoMainInput.click()">
                                    <h3 class="dropzone-msg-title">{{ $t("settings.title.logo_main") }}</h3>
                                    <span class="text-muted">250x70px</span>
                                    <div class="py-7" v-if="main_logo_base64">
                                        <img v-if="main_logo_base64" :src="'data:image/png;base64,' + main_logo_base64" class="w-100" />
                                    </div>
                                    <div v-else>
                                        <div class="py-7" v-if="main_logo_url">
                                            <img v-if="main_logo_url" :src="main_logo_url" class="w-100" />
                                        </div>
                                        <div class="py-12" v-else>
                                        <span class="text-muted" v-html="$t('settings.title.logo_notset')"></span>
                                        </div>
                                    </div>
                                </div>
                                <span href="" class="btn btn-primary mt-3" @click="$refs.LogoMainInput.click()" >{{ $t("button.select") }}</span>
                                <span href="" class="btn btn-danger mt-3 ml-3" v-if="main_logo || main_logo_base64" @click="deleteLogo('main')">{{ $t("button.delete") }}</span>
                            </div>
                        </div>
                        <input ref="LogoMainInput" type="file" style="display: none;" @change="onLogoMainSelect" />
                </div>
                <div class="col-lg-12 col-xl-6">   
                        <div class="dropzone dropzone-default bg-dark dz-clickable">
                            <div class="dropzone-msg dz-message needsclick">
                            <div @click="$refs.LogoWhiteInput.click()" >
                                <h3 class="dropzone-msg-title text-white">{{ $t("settings.title.logo_white") }}</h3>
                                <span class="text-muted">350x100px</span>

                                <div class="py-7" v-if="white_logo_base64">
                                    <img v-if="white_logo_base64" :src="'data:image/png;base64,' + white_logo_base64" class="w-100" />
                                </div>
                                <div v-else>
                                    <div class="py-7" v-if="white_logo_url">
                                        <img v-if="white_logo_url" :src="white_logo_url" class="w-100" />
                                    </div>
                                    <div class="py-12" v-else>
                                        <span class="text-muted" v-html="$t('settings.title.logo_notset')"></span>
                                    </div>  
                                </div>
                            </div>
                            <span href="" class="btn btn-primary mt-3" @click="$refs.LogoWhiteInput.click()" >{{ $t("button.select") }}</span>
                            <span href="" class="btn btn-danger mt-3 ml-3" v-if="white_logo || white_logo_base64" @click="deleteLogo('white')">{{ $t("button.delete") }}</span>
                         </div>
                    </div>
                    <input ref="LogoWhiteInput" type="file" style="display: none;" @change="onLogoWhiteSelect" />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  components: {     
  },
   data() {
    return {
      main_logo: null,
      main_logo_url: null,
      main_logo_base64: null,
      white_logo: null,
      white_logo_url: null,
      white_logo_base64: null,
    }
  },
  metaInfo () { 
      return { title: this.$t("settings.meta.subtitle.apperance")} 
  },
  mounted() {
    this.loadLogos();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("settings.meta.title"), subtitle: this.$t("settings.meta.subtitle.apperance") }]);
  },
  methods: {
    onLogoMainSelect(e) {
      this.main_logo = e.target.files[0];
      this.main_logo_url = URL.createObjectURL(this.main_logo);
      this.main_logo_base64 = null;
    },
    onLogoWhiteSelect(e) {
      this.white_logo = e.target.files[0];
      this.white_logo_url = URL.createObjectURL(this.white_logo);
      this.white_logo_base64 = null;
    },
    saveLogo() {
        // Loading spinner
      const submitButton = this.$refs["save_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        let formData = new FormData();
        if(this.main_logo!=null){
            formData.append('main_logo', this.main_logo);
        }
        if(this.white_logo!=null){
            formData.append('white_logo', this.white_logo);
        }
        ApiService.setFileHeader();
        ApiService.apiPost("/settings/apperance", formData)
              .then(() => {
                  this.loading = false;
                  submitButton.classList.remove("spinner","spinner-light","spinner-right");
              }).catch(error => {
                  submitButton.classList.remove("spinner","spinner-light","spinner-right");
                  checkErrors(error);
        });
        
    },
    loadLogos(){
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/settings/apperance")
        .then((response) => {
          this.main_logo_base64 = (response.data.main_logo) ? response.data.main_logo.image_base_64 : null;
          this.white_logo_base64 = (response.data.white_logo) ? response.data.white_logo.image_base_64 : null;
          this.loading = false;
        });
    },
    deleteLogo(type)
    {
        switch(type)
        {
            case 'main':
                this.main_logo = null;
                this.main_logo_url = null;
                this.main_logo_base64 = null;
            break;
            case 'white':
                this.white_logo = null;
                this.white_logo_url = null;
                this.white_logo_base64 = null;
            break;
        }

        ApiService.apiPost("/settings/apperance/"+ type + "_logo/delete",{})
        .then(() => {
            this.loading = false;
            this.loadLogos();
        }).catch(error => {
            checkErrors(error);
        });
    }
  }
};
</script>
